@media only screen and (min-width: 576px) {
    .header {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .img {
        width: 427px;
        height: 427px;
    }

    .img-container {
        padding: 24px;
        justify-content: center;
    }
}

@media only screen and (max-width: 576px) {
    .header {
        margin: 48px;
    }

    .img {
        margin-top: 24px;
        width: 280px;
        height: 280px;
        align-content: center;
    }


    .img-container {
        display: flex;
        justify-content: center;
    }
}